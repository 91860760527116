import React from 'react'

const Footer = class extends React.Component {
  render() {
    return (
    
        <footer className="footer is-dark">
           
                 <div class="content has-text-centered">
                    <a className="footerlink" href="/privacy-policy/">Privacy Policy</a>
                    <a className="footerlink" href="/terms-of-use/">Terms of Use</a>
                    <a className="footerlink" href="/about/">About</a>
                    <a className="footerlink" href="/contact/">Contact</a>
                </div>
                    
                    <hr></hr>
                    <div className="columns">
                    <div className="column has-text-centered has-text-left-tablet ">
                        <p className="subtitle is-7 has-text-weight-bold"> This site is not affiliated with <a href="http://expresscv.com">http://expresscv.com</a></p>
                    </div>
                    <div className="column has-text-centered has-text-right-tablet">
                        <p className="subtitle is-7 has-text-weight-bold"> © Bold Limited. All Rights Reserved.</p>
                    </div>
                    </div>
                
        </footer>
    )
  }
}

export default Footer
